$primaryColor1 : rgb(39, 77, 100);
$primaryTextColor: rgb(235, 235, 235);
$colorBackText : rgb(28,43,53);
$colorBackTextRGBA : rgba(30, 46, 56, 0.95);
$colorBackTextRGBA1 : rgba(223, 223, 223, 0.95);
$colorBackTextRGBA2 : rgba(223, 223, 223, 0.85);
$colorTextHover: #3897c6;
$primaryColorRGBA : rgba(39, 77, 100,0.9);
$navbarOddColor:rgb(40, 83, 108);
$primaryColor:rgba(19,34,53,255);

$primeBackgroundColor : rgba(36,35,33,1);
$facebookColor: rgba(13,110,253,1);

