.form-sides{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 2rem;
    width: 45%;
    span{
        padding-left: 5px;
    }
    p{
        text-align: left;
        color: #858282;
    }
}
.form-inputs{
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 11px;
    padding: 15px;
    color: black;
    font-size: 1.3rem;
}

.submit-button{
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    background-color: $primaryColor;
    border-radius: 11px;
    border: 0 solid black;
    justify-content: space-between;
    div:last-child {
        padding-left: 10px;
      }
}

.rodo{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;

   background-color: rgb(235, 235, 235);

    
    text-align: left;
}