.offert-list-container{
    margin: 3rem 0;
}
.offerd-cards
{
    .nav-bottom-item-text{
      font-size: 1.3rem;}

}

.offert-desc{
    width: 60%;
padding: 10px;

}
.offert-row{
svg{
    color: gray;
}
}
.offert-row:nth-of-type(even){
    background: $primaryTextColor;
    div svg{
        color: #5bb7ed;
    }
}
.offert-row{
    div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        padding: 20px
    }
    .offert-desc{
        text-align: initial;

    }
}
.offerts-link-to{
    color:black;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {


    .offert-row{
        
        .offert-desc{
            text-align: center;
    
        }
            
        }

}
