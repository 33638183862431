.logo-back-shadow
{
    background-color: rgba(228, 228, 228, 0.7);
    color:black;
    position:absolute;
    max-width: 500px;
    
    padding: 20px;
    // transform: translate(-20%,-0%);
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 10px;
}   
.center-logo{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.parallax-container{
    height: 450px;
}
.logo-info{
    width: 44vw;
    max-width: 450px;
}