.modal-mail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(223, 229, 255, 0.51);
}
.modal-fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.modal-fade.show {
  opacity: 1;
  //   display: block;
}
.modal-dialog {
}
